<template>
  <div class="home">
    <b-card>
      <b-card-header>
        <h1 class="text-center w-100">{{msg('Welcome')}} {{myName}}</h1>
      </b-card-header>
    </b-card>
    <b-card>
      <b-card-header>
        <h3 class="w-100">{{msg('Apps & Pages')}}</h3>
      </b-card-header>
      <div class="text-justify " >
        <b-button
            v-for="page in pages" v-bind:key="page.route"
            variant="flat-secondary"
            class="btn-icon border m-2 p-2"
            @click="$router.push(page.route)"
        >
          <div>
            <i v-if="page.icon_type === 'fontawesome'" :class="{[page.icon]:true}" style="font-size: 100px"/>
            <feather-icon v-else :icon="page.icon" size="100"></feather-icon>
          </div>
          <div class="pt-1"></div>
          <div><strong>{{msg(page.title)}}</strong></div>
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'home',

  data: () => ({
  }),
  computed:{
    ...mapGetters('theme',['navigation']),
    ...mapGetters('user',['myName']),
    pages(){
      return this.navigation.flatMap(n=>[n,...(n.children || [])]).filter(n=>n.route != null&& n.icon != null);
    }
  },
}
</script>
